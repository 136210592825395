import { gql } from '@apollo/client/core';

export const claimsCount = gql`
  query ClaimsCount($storeId: [String!], $createdAtMin: DateTime, $createdAtMax: DateTime) {
    claimsCount(storeId: $storeId, filter: { createdAtMin: $createdAtMin, createdAtMax: $createdAtMax }) {
      all
      approved
      denied
      open
      new
      dismissed
      closed
    }
  }
`;

export const getStoreCreditClaims = gql`
  query claimStoreCredits(
    $after: String
    $first: Int
    $before: String
    $last: Int
    $include: [String!]
    $type: StoreCreditType
    $createdAtMin: DateTime
    $createdAtMax: DateTime
  ) {
    claimStoreCredits(
      after: $after
      first: $first
      before: $before
      last: $last
      include: $include
      filter: { createdAtMin: $createdAtMin, createdAtMax: $createdAtMax, type: $type }
    ) {
      edges {
        node {
          type
          code
          emailStatus
          emailTime
          creditValue
          bonusValue
          totalValue
          creditCurrency
          exchangeRate
          claim {
            orderId
            sourceOrderNumber
            authorName
            authorEmail
          }
        }
        cursor
      }
      pageInfo {
        hasNextPage
        endCursor
        startCursor
        endCursor
        hasPreviousPage
      }
    }
  }
`;

export const getClaims = gql`
  query getClaims(
    $after: String
    $first: Int
    $before: String
    $include: [String!]
    $last: Int
    $storeId: [String!]
    $claimState: ClaimState
    $search: String
    $createdAtMin: DateTime
    $createdAtMax: DateTime
  ) {
    claims(
      after: $after
      first: $first
      before: $before
      last: $last
      include: $include
      filter: {
        storeId: $storeId
        claimState: $claimState
        search: $search
        createdAtMin: $createdAtMin
        createdAtMax: $createdAtMax
      }
    ) {
      edges {
        cursor
        node {
          id
          storeId
          sourceOrderId
          sourceOrderNumber
          orderId
          authorName
          isOta
          claimDescription
          resolutionDate
          claimState
          claimType
          externalSource
          createdAt
          policy {
            id
            insuredAmount
            premiumPaid
            premiumDiscountedAmount
            premiumCurrency
            fundedBy
            removedAt
          }
          claimResolutions {
            resolutionType
            createdAt
          }
          claimStoreCredits {
            type
            creditValue
            totalValue
            bonusValue
          }
        }
      }
      pageInfo {
        startCursor
        endCursor
        hasPreviousPage
        hasNextPage
      }
    }
  }
`;

export const getClaimsByOrderId = gql`
  query Claims($id: String!, $include: [String!]) {
    claims(include: $include, filter: { search: $id }) {
      edges {
        cursor
        node {
          id
          storeId
          orderId
          sourceOrderId
          sourceOrderNumber
          authorName
          authorEmail
          claimDescription
          resolutionRequested
          claimState
          claimType
          isOta
          otaDescription
          externalSource
          policyId
          createdAt
          updatedAt
          deletedAt
          claimItems {
            id
            name
            claimId
            orderId
            sourceOrderId
            resolutionId
            resolutionRequested
            resolutionFinal
            resolutionQuantity
            resolutionPercentage
            sourceVariantId
            sourceItemId
            isAdditionalItem
            claimType
            details
            sourceProductId
            sku
            images
            imageUrl
            quantity
            createdAt
            updatedAt
            deletedAt
          }
          claimMessages {
            id
            messageContent
            senderName
            senderType
            senderId
            readAt
            claimId
            createdAt
            updatedAt
            deletedAt
            medias {
              id
              claimMessageId
              fileKey
              downloadLink
              filename
              caption
              createdAt
              updatedAt
              deletedAt
            }
          }
          claimResolutions {
            id
            claimId
            resolutionDetails
            shippingAmount
            paidValue
            paidCurrency
            paidOriginalValue
            creditStore
            resolutionType
            quantity
            createdAt
            updatedAt
            deletedAt
            user {
              firstName
              lastName
              fullName
              nickname
              email
              createdAt
            }
          }
          claimCredits {
            id
            claimId
            claimResolutionId
            creditAmount
            creditTarget
            creditCurrency
            creditReason
            creditIssuedBy
            paymentGateway
            createdAt
            updatedAt
            deletedAt
          }
          activities {
            id
            claimId
            description
            createdAt
          }
          policy {
            id
            orderId
            storeId
            insuredAmount
            premiumPaid
            premiumDiscountedAmount
            premiumCurrency
            fundedBy
            createdAt
            updatedAt
            deletedAt
          }
          claimStoreCredits {
            type
            bonus
            bonusType
            creditValue
            creditCurrency
            totalValue
            bonusValue
          }
        }
      }
      pageInfo {
        startCursor
        endCursor
        hasPreviousPage
        hasNextPage
      }
    }
  }
`;

export const getOneTimeAppeasements = gql`
  query oneTimeAppeasements($startDate: String!, $endDate: String!, $storeId: String!) {
    oneTimeAppeasements(startDate: $startDate, endDate: $endDate, storeId: $storeId) {
      id
      paidValue
      creditStore
      createdAt
      resolutionType
      claim {
        createdAt
        claimType
        sourceOrderNumber
      }
    }
  }
`;
